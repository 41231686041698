@import "~react-image-gallery/styles/css/image-gallery.css";



main img {
    width: 100%;
    border: 3px solid var(--text-color);
}

section {
    width: clamp(300px, 90%, 1000px);
    margin: 0 auto;
}

section ul {
    padding-left: 0;

}

.project-entry, .sound-entry {
    display: flex;
    flex-wrap: wrap;
    gap: 0 2rem;
    margin: 6rem auto;
}

.project-entry img {
    border: none;
}

.project-entry:first-of-type {
    margin-top: 3rem;
}


.thumbnail {
    flex-basis: 400px;
    flex-grow: 1;
}

.description {
    flex-basis: 400px;
    flex-grow: 1;
}

.description h2 {
    margin: 1rem 0;
}

.art-entry {
    column-count: 2;
    column-gap: 1rem;
}

.art-entry img {
    width: 100%;
    margin-bottom: 0.5rem;
}

.sound-entry {
    flex-wrap: wrap-reverse;
    margin: 3rem auto;
}

main button {
    padding: 0.7rem 1rem;
    border-radius: 10px;
    border: 3px solid var(--text-color);
    background-color: transparent;
    color: var(--text-color);
    font-size: 1rem;
    font-family: Outfit, var(--header-font),'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 2px;
}

main button:hover {
    background-color: rgba(139, 158, 108, 0.247)
}

@media screen and (max-width: 1000px) {  
    div.art-entry {
      column-count: 1;
    }
  }