@font-face {
  font-family: Netigen;
  src: url('./fonts/netigen.otf');
}

@font-face {
  font-family: Outfit;
  src: url("./fonts/Outfit/Outfit-VariableFont_wght.ttf"); 
}

@font-face {
  font-family: Outfit-Thin;
  src: url("./fonts/Outfit/static/Outfit-Thin.ttf");
}

:root {
  --transparent: rgba(0,0,0,0);
  --body-color: linear-gradient(rgb(22, 12, 12), rgb(25, 43, 35), rgb(49, 68, 64) 80%);
  --menu-color: linear-gradient(rgb(0 0 0),rgb(36 15 15),rgb(55 17 17));
  --text-color: #cccac3;
  --header-font: 'Outfit', 'Courier New', Courier, monospace;
}


/*My page*/
.hidden {
  display: none;
}

.divider {
  border: 1px solid var(--text-color);
  margin: auto 0;
}

.caps {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

p {
  color: var(--text-color);
}

h1 {
  margin: 5px 0;
  font-family: Outfit-Thin, var(--header-font);
  color: var(--text-color);
  font-size: min(15vw, 5rem);
  font-weight: 200;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h2 {
  font-family: Outfit-Thin, var(--header-font);
  color: var(--text-color);
  font-size: min(10vw, 2rem);
}

.App {
  display: grid;
  grid-template-columns: 300px auto; 
  grid-template-areas: 100vh;
  height: 100vh;
  background: var(--body-color); 
  align-items: stretch; 
  margin: 0; 
}

/* SIDE MENU */
nav {
  display: grid;
  box-sizing: border-box;
  grid-template-rows: 4rem auto 3rem 10rem;
  padding: 1.5rem;
  border-right: 5px dashed var(--text-color);
  background: var(--menu-color);
  text-align: right;
  font-size: 1.5rem;
  color: var(--text-color);
}

.nav-tabs a {
  font-family: var(--header-font);
  text-decoration: none;
  text-transform: capitalize;
  fill: var(--text-color);
}

.nav-tabs a:hover {
  text-decoration: underline;
}

.nav-tabs a:visited {
  font-style: normal;
  fill: var(--text-color);
}

.nav-tabs > * {
  display: block;
  letter-spacing: 2px;
  text-align: right;
}


/* FOOTER */
footer a:hover {
  text-decoration: underline;
}

.external-icons svg {
  border-radius: 100%;
  color: var(--text-color);
  background-color: var(--transparent);
}

svg.menu {
  display: none;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  width: 40px;
  height: 40px;
  fill: var(--text-color);
}

@media screen and (max-width: 800px) {
  .App {
    grid-template-columns: auto;
  }
  
  nav {
    position: fixed;
    float: left;
    width: min(100vw, 500px);
    height: 100vh;
    translate: -100%;
  }

  svg.menu {
    display: block;
  }
}

